import(/* webpackMode: "eager", webpackExports: ["CohesionContext","CohesionProvider","useMonarch","useMonarchRule","useMonarchRequest","useMonarchSlot","useMonarchSlotContext","useMonarchPreampPlacement","useMonarchPreampPlacementContext","usePreamp","usePreampPlacement","usePreampPlacementExists","usePreampAsset","usePreampAssetExists","MonarchSlotContext","MonarchSlot","MonarchPreampPlacementContext","MonarchPreampPlacement"] */ "/workspace/node_modules/@highereducation/cohesion-utils-react/dist/CohesionProvider.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/architecture-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/business-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/compsci-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/datasci-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/education-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/engineering.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/law-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/math-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/medicine-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/public/search-filters/nutrition-filter.png");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Breadcrumbs/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CardGrid/variants/Flex.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientOnly"] */ "/workspace/src/components/Cohesion/ClientOnly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Cohesion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Hero/variants/BackgroundImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Hero/variants/NoImage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Link/LinkWithChildren/LinkWithChildren.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/ColumnLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/ContentColumns.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/LinkList/variants/Dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/FlexLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/ImagesSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductCardListWrapper/variants/ProductCardListByUUID/ProductCardListByUUIDClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductSearch/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Search/SearchClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["needsNewPageView"] */ "/workspace/src/utils/tagular/needsNewPageView.ts");
